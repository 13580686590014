import React, { Fragment, useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { useSprings, animated } from "react-spring"

import Seo from "../components/Seo"
import Spacer from "../components/Spacer"
import FlexibleContent from "../components/FlexibleContent"
import BlogPreview from "../components/BlogPreview"
import { breakpoints } from "../assets/globalStyles"

const StyledBlogHeader = styled.div`
  background-color: #d42b69;
  max-height: 450px;
  overflow: hidden;
  margin-top: -24px;
  img {
    object-fit: contain;
    object-position: bottom center;
    width: 100%;
  }
`
const StyledBlogTitle = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    font-family: Poppins;
    @media screen and (min-width: ${breakpoints.md}px) {
      font-size: 34px;
      font-weight: 600;
      line-height: 52px;
    }
  }

  .blog-info {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${breakpoints.md}px) {
      flex-direction: row;
      justify-content: space-between;
    }
    .details {
      color: #66768e;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      span {
        color: #f03a85;
      }
    }
    .category {
      padding: 8px 16px;
      color: #ffffff;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      background-color: #4f287c;
      display: inline-block;
      will-change: transform;
    }
  }
`

const StyledShareLinks = styled.div`
  display: flex;
  @media screen and (min-width: ${breakpoints.lg}px) {
    flex-direction: column;
  }
`

const StyledCategoryTabs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: ${breakpoints.md}px) {
    flex-direction: row;
  }
`

export const blog = graphql`
  query($id: String!, $categoryNames: [String!]) {
    wpPost(id: { eq: $id }) {
      id
      title
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      date(formatString: "DD/MM/YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH
                quality: 90
              )
            }
            publicURL
          }
          mimeType
        }
      }
      author {
        node {
          firstName
          lastName
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      flexibleGeneralContentFields {
        ...FlexibleContentPost
      }
    }
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { in: $categoryNames } } } }
        id: { ne: $id }
      }
      limit: 2
    ) {
      nodes {
        id
        title
        slug
        date(formatString: "DD/MM/YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
              publicURL
            }
            mimeType
          }
        }
        author {
          node {
            firstName
            lastName
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    wp {
      options {
        optionsPageFields {
          shareLinks {
            link
            platformName
            icon {
              mimeType
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    height: 40
                    quality: 90
                    placeholder: TRACED_SVG
                    layout: FIXED
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
export default function BlogTemplate({
  data: {
    wpPost: {
      categories: { nodes: categories },
      featuredImage,
      flexibleGeneralContentFields: { content: pageContent },
      seo,
      title,
      date,
      author: {
        node: { firstName, lastName },
      },
    },
    wp: {
      options: {
        optionsPageFields: { shareLinks },
      },
    },
    allWpPost: { nodes: relatedBlogPosts },
  },
}) {
  // Categories animations
  const [hoveredCategoryIndex, setHoveredCategoryIndex] = useState()

  const categoriesAnimations = useSprings(
    categories.length,
    categories.map((_, index) => ({
      transform: hoveredCategoryIndex === index ? "scale(1.05)" : "scale(1)",
      config: {
        tension: 300,
        friction: 15,
      },
    }))
  )

  //combine animation properties with categories
  const animatedCategoryItems = categories.map((category, index) => ({
    ...category,
    animationProps: categoriesAnimations[index],
  }))

  const AnimatedCategory = animated(Link)

  // social links animations
  const [hoveredSocialLinkIndex, setHoveredSocialLinkIndex] = useState()

  const socialLinkAnimations = useSprings(
    shareLinks.length,
    shareLinks.map((_, index) => ({
      transform: hoveredSocialLinkIndex === index ? "scale(1.15)" : "scale(1)",
      config: {
        tension: 300,
        friction: 10,
      },
    }))
  )

  //combine animation properties with categories
  const animatedSocialLink = shareLinks.map((shareLink, index) => ({
    ...shareLink,
    animationProps: socialLinkAnimations[index],
  }))

  // Containers are doubled up to make the layout right since flexible contanent is technically double containered here
  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <StyledBlogHeader>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {featuredImage.node.mimeType === "image/svg+xml" ? (
                <img src={featuredImage.node.localFile.publicURL} alt={title} />
              ) : (
                <GatsbyImage
                  image={
                    featuredImage.node.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={title}
                  objectFit="contain"
                  objectPosition="bottom center"
                  loading="eager"
                  style={{ maxHeight: "100%", height: "100%", width: "100%" }}
                />
              )}
            </div>
          </div>
        </div>
      </StyledBlogHeader>
      <Spacer size={32} when={{ md: 40 }} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="container">
              <div className="row">
                <StyledBlogTitle className="col-12">
                  <div
                    dangerouslySetInnerHTML={{ __html: `<h1>${title}</h1>` }}
                  />
                  <Spacer size={16} when={{ sm: 24 }} />
                  <div className="blog-info">
                    <p className="details">
                      {`${date} | `} <span>{`${firstName} ${lastName}`}</span>
                    </p>
                    <Spacer size={16} when={{ md: 0 }} />
                    <StyledCategoryTabs>
                      {animatedCategoryItems.map(
                        ({ slug, name, animationProps }, index) => {
                          const isLast =
                            index === animatedCategoryItems.length - 1
                          return (
                            <Fragment key={index}>
                              <AnimatedCategory
                                to={`/blog/${slug}/`}
                                className="category"
                                style={animationProps}
                                onMouseEnter={() => {
                                  setHoveredCategoryIndex(index)
                                }}
                                onMouseLeave={() => {
                                  setHoveredCategoryIndex()
                                }}
                              >
                                {name}
                              </AnimatedCategory>
                              {!isLast && <Spacer size={16} />}
                            </Fragment>
                          )
                        }
                      )}
                    </StyledCategoryTabs>
                  </div>
                </StyledBlogTitle>
                <Spacer size={16} when={{ lg: 40 }} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-1">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <StyledShareLinks>
                    {animatedSocialLink.map(
                      ({ icon, link, platformName, animationProps }, index) => {
                        const isPinterest = platformName
                          .toLowerCase()
                          .includes("pinterest")
                        const currentUrl =
                          typeof window === "undefined"
                            ? ""
                            : window.location.href

                        const shareLink = isPinterest
                          ? `${link}${currentUrl}&title=${title}`
                          : link + encodeURI(currentUrl)

                        return (
                          <Fragment key={platformName}>
                            <animated.div style={animationProps}>
                              <a
                                href={shareLink}
                                target="__blank"
                                rel="noreferrer"
                                onMouseEnter={() => {
                                  setHoveredSocialLinkIndex(index)
                                }}
                                onMouseLeave={() => {
                                  setHoveredSocialLinkIndex()
                                }}
                              >
                                {icon.mimeType === "image/svg+xml" ? (
                                  <img
                                    src={icon.localFile.publicURL}
                                    alt={platformName}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  />
                                ) : (
                                  <GatsbyImage
                                    image={
                                      icon.localFile.childImageSharp
                                        .gatsbyImageData
                                    }
                                    alt={platformName}
                                    objectFit="contain"
                                    onMouseEnter={() => {
                                      setHoveredSocialLinkIndex(index)
                                    }}
                                    onMouseLeave={() => {
                                      setHoveredSocialLinkIndex()
                                    }}
                                  />
                                )}
                              </a>
                            </animated.div>
                            <Spacer size={16} />
                          </Fragment>
                        )
                      }
                    )}
                  </StyledShareLinks>
                  <Spacer size={32} when={{ lg: 0 }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-10">
            <FlexibleContent content={pageContent} />
          </div>
        </div>
      </div>
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
      <BlogPreview relatedBlogPosts={relatedBlogPosts} />
    </>
  )
}
